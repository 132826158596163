import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import title_image from '../../images/titles/it-strategy.jpg'

const pageTitle = "IT Strategy";
const pageSlug = "it-strategy";


class ITStrategy extends React.Component{
    render(){
      return(
        <Layout>

<section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">To increase your value creation, improve your IT capabilities.</h6>
        </div>
</section>

<section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    
                  <h3>To take advantage of your technological capabilities, it is important to grasp the full essence of IT Strategy.</h3>
                  <p>Like other key tasks in an enterprise, IT can improve business efficiency and is essential to any major strategic initiative.</p>
                  <p>Our IT experts will collaborate with your current IT staff to figure out how your business strategy could benefit from technology. Or find out what your IT can offer actual,  as well as manageable benefits that increase value production.</p>
                  <p>Together, we will measure the ROI that can be derived from the market's leading technologies.</p>
                  <span className="break"></span>
                  <h5>IT Service Management </h5>
                  <p>We ensure that your products/service is delivered reliably by IT and addresses your business concerns.</p>
                  <h5>IT Strategy Resources </h5>
                  <p>We build your IT resources to create value for your company consistently. You get a collaborative, decision-driven and execution-focused approach with us to give your customers better value.We build your IT resources to create value for your company consistently. You get a collaborative, decision-driven and execution-focused approach with us to give your customers better value.</p>
                  <h5>Business and IT Alignment</h5>
                  <p>We shed light on IT and show our partners how it can serve as a creator of strategic assets and value. Find out more about how to boost IT value:</p>
                  <ul>
                    <li>Corporate and IT Alignment</li>
                    <li>Enterprise Architecture</li>
                    <li>IT Strategy Assessments</li>
                    <li>IT Leadership</li>
                    <li>IT Portfolio Lifecycle Management</li>
                    <li>IT Service Management</li>
                  </ul>
                  <p>Let us advise you on topics, including employees, suppliers, financing, software, and other critical areas, on how to handle your IT organization. We offer:</p>
                  <ul>
                    <li>CIO Mentoring</li>
                    <li>IT Transformation</li>
                    <li>IT Organizational and Cost Assessments</li>
                    <li>Software Selection Services</li>
                    <li>Vendor Strategy Development</li>
                  </ul>
                  <p>Our specialists will assist you in quickly implementing changes to your IT infrastructure, providing advice on change strategy and leadership management services and staff. We provide the following:</p>
                  <ul>
                    <li>Change Readiness Assessments</li>
                    <li>Organization Redesign Projects</li>
                    <li>IT Strategy Development Process</li>
                    <li>Business Continuity and Disaster Avoidance Design</li>
                    <li>Portfolio and Program Management Services</li>
                    <li>IT Service Management</li>
                  </ul>
                  

                  
                </div>
            </div>
        </div>
      </section>



</Layout>
    )
  }
}

export default ITStrategy
